<template>
  <div class="footer">
  <div class="footContent">
    <div class="footContentLeft">
      <div class="logoFooter"></div>
      <div class="qrcode"></div>
      <div class="qrFont">
        关注星航小院仔企业微信，第一时间获得星航小院仔产品、服务信息以及更多的在线支持。
      </div>
    </div>
    <div class="footContentRight">
      <div class="lxfs">联系方式</div>
      <div class="lxfs1">
        <img src="../assets/header/address.png" />
        <div>地址：北京市石景山区八大处路点石商务公园6号楼</div>
      </div>
      <div class="lxfs1">
        <img src="../assets/header/tel1.png" />
        <div>电话：400 966 3880</div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'footerPart1'
};
</script>
<style lang="less">
.footer {
  width: 100%;
  min-height: 564/19.2vw;
  background-color: #181315;
  padding-top: 90/19.2vw;
  .footContent {
    text-align: left;
    width: 1200/19.2vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .footContentLeft {
      width: 504/19.2vw;
      .logoFooter {
        width: 246/19.2vw;
        height: 77/19.2vw;
        background: url('../assets/header/logoFooter.png');
        background-size: 100% 100%;
      }
      .qrcode {
        width: 124/19.2vw;
        height: 121/19.2vw;
        background: url('../assets/header/qrcode.png');
        background-size: 100% 100%;
        margin: 51/19.2vw 0 17/19.2vw 10/19.2vw;
      }
      .qrFont {
        font-size: 20/19.2vw;
        font-family: 'Microsoft YaHei';
        color: rgb(255, 255, 255);
        line-height: 1.2;
        margin-left: 10/19.2vw;
        // white-space: nowrap;
      }
    }
    .footContentRight {
      padding-top: 65/19.2vw;
      &.forIndex {
        padding-top: 0;
        .lxfs,
        .lxfs1 {
          margin-left: 360/19.2vw;
        }
        .lxfs {
          margin-top: 60/19.2vw;
        }
      }
      .lxfs {
        font-size: 24/19.2vw;
        font-family: 'Microsoft YaHei';
        color: rgb(255, 255, 255);
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 43/19.2vw;
      }
      .lxfs1 {
        display: flex;
        align-items: center;
        font-size: 20/19.2vw;
        font-family: 'Microsoft YaHei';
        color: rgb(255, 255, 255);
        line-height: 1.2;
        width: 330/19.2vw;
        margin-bottom: 40/19.2vw;
        img {
          margin-right: 15/19.2vw;
          &:nth-child(1) {
            width: 21/19.2vw;
            height: 31/19.2vw;
          }
          &:nth-child(2) {
            width: 23/19.2vw;
            height: 23/19.2vw;
          }
        }
      }
    }
  }

  .footerPage_mySwiper_Parent {
    width: 825/19.2vw;
    height: 103/19.2vw;
    position: relative;
    // padding: 0 60/19.2vw;
    .swiperLeft {
      position: absolute;
      width: 19/19.2vw;
      height: 32/19.2vw;
      background: url('../assets/index/left.png');
      background-size: 100% 100%;
      top: 50%;
      transform: translate(-50%, 0);
      left: 0;
      cursor: pointer;
    }
    .swiperRight {
      position: absolute;
      width: 19/19.2vw;
      height: 32/19.2vw;
      background: url('../assets/index/right.png');
      background-size: 100% 100%;
      top: 50%;
      transform: translate(-50%, 0);
      right: -20/19.2vw;
      cursor: pointer;
    }
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }
  .footerPage_mySwiper {
    width: 760/19.2vw;
    height: 103/19.2vw;
  }

  .swiper-slide {
    position: relative;
    top: 16/19.2vw;
    width: 130/19.2vw;
    height: 87/19.2vw;
    .ryImg {
      width: 130/19.2vw;
      height: 87/19.2vw;
    }
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    position: relative;
    top: 0;
  }
}
</style>
