<template>
  <div class="home">
    <headerPart />
    <router-view />
    <footerPart1 v-if="$route.name == 'home'" />
    <footerPart2 v-if="$route.name != 'home'" />
  </div>
</template>

<script>
import headerPart from './headerPart.vue';
import footerPart1 from './footerPart1.vue';
import footerPart2 from './footerPart2.vue';

export default {
  name: 'Home',
  components: {
    headerPart,
    footerPart1,
    footerPart2
  }
};
</script>
