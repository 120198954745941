<template>
  <div class="headerPart">
    <div class="header1Parent">
      <div class="header1">
        <div class="tel"></div>
        <div class="telNum">400 966 3880</div>
        <div class="msgBg">
          <img src="../assets/header/msg.png" />
        </div>
      </div>
    </div>
    <div class="header2Parent">
      <div class="header2" :class="{ small: isSmall }">
        <a href="javascript:void(0)" @click="toRouter('home')">
          <div class="logo"></div>
        </a>
        <div class="menus">
          <div
            class="menu_list_item"
            :class="{ active: $route.name == 'home' }"
            data-type="index"
            @click="toRouter('home')"
          >
            <a href="javascript:void(0)">首页</a>
            <div class="orangeBlock"></div>
          </div>
          <div
            class="menu_list_item"
            :class="{ active: $route.name == 'ppjs' }"
            data-type="ppjs"
            @click="toRouter('ppjs')"
          >
            <a href="javascript:void(0)">品牌介绍</a>
            <div class="orangeBlock"></div>
          </div>
          <div
            class="menu_list_item"
            :class="{ active: $route.name == 'kctx' }"
            data-type="kctx"
            @click="toRouter('kctx')"
          >
            <a href="javascript:void(0)">课程体系</a>
            <div class="orangeBlock"></div>
            <!-- target="_black" -->
          </div>
          <div
            class="menu_list_item"
            :class="{ active: $route.name == 'zshz' }"
            data-type="zshz"
            @click="toRouter('zshz')"
          >
            <a href="javascript:void(0)">招商合作</a>
            <div class="orangeBlock"></div>
          </div>
          <div
            class="menu_list_item"
            :class="{ active: $route.name == 'qgxq' }"
            data-type="qgxq"
            @click="toRouter('qgxq')"
          >
            <a href="javascript:void(0)">全国校区</a>
            <div class="orangeBlock"></div>
          </div>
          <div
            class="menu_list_item"
            :class="{ active: $route.name == 'xwdt' }"
            data-type="xwdt"
            @click="toRouter('xwdt')"
          >
            <a href="javascript:void(0)">新闻动态</a>
            <div class="orangeBlock"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerPart',
  data() {
    return {
      isSmall: false
    };
  },
  mounted() {
    let _this = this;
    window.addEventListener('resize', function () {
      if (document.body.offsetWidth < 1000) {
        _this.isSmall = true;
      } else {
         _this.isSmall = false;
      }
    });
    if (document.body.offsetWidth < 500) {
      this.isSmall = true;
    }
  },
  methods: {
    toRouter(name) {
      this.$router.push({
        name
      });
    }
  }
};
</script>
<style lang="less">
.headerPart {
  // position: fixed;
  // top:0;
  // z-index: 999;
  width: 100%;
  // min-width: 1340/19.2vw;
  .header1Parent {
    background: #f3f3f3;
    width: 100%;
  }
  .header1 {
    width: 1270/19.2vw;
    height: 60/19.2vw;
    margin: 0 auto;
    // background: #f3f3f3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .tel {
      width: 26/19.2vw;
      height: 26/19.2vw;
      background: url('../assets/header/tel.png');
      background-size: 100% 100%;
      margin-right: 14/19.2vw;
    }
    .telNum {
      font-size: 14/19.2vw;
      line-height: 14/19.2vw;
      color: #494949;
      margin-right: 27/19.2vw;
    }
    .msgBg {
      width: 60/19.2vw;
      height: 60/19.2vw;
      background-color: #027dd3;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 28/19.2vw;
        height: 26/19.2vw;
      }
    }
  }
  .header2Parent {
    width: 100%;
    background-color: #fffeff;
    .header2 {
      &.small {
        width: 100%;
        .menu_list_item {
          white-space: nowrap;
          margin-left: 8px;
        }
      }
      width: 1270/19.2vw;
      height: 155/19.2vw;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 36/19.2vw;
      .logo {
        width: 384/19.2vw;
        height: 109/19.2vw;
        background: url('../assets/header/logo.png');
        background-size: 100% 100%;
        margin-top: 17/19.2vw;
        margin-bottom: 29/19.2vw;
      }
    }
  }
  .menus {
    display: flex;
    justify-content: flex-end;
    .menu_list_item {
      font-size: 18/19.2vw;
      line-height: 18/19.2vw;
      font-family: Microsoft YaHei;
      font-weight: bold;
      margin-left: 55/19.2vw;
      //   border-bottom: 5/19.2vw solid rgba(0, 0, 0, 0);
      //   border-radius: 3/19.2vw;
      .orangeBlock {
        height: 5/19.2vw;
        background-color: #fc9627;
        border-radius: 3/19.2vw;
        display: none;
        margin-top: 14/19.2vw;
      }
      a {
        color: #3b3839;
      }
      &:hover {
        // border-bottom: 5/19.2vw solid #fc9627;
        a {
          color: #0f71a6;
        }
        .orangeBlock {
          display: block;
        }
      }
      &.active {
        // border-bottom: 5/19.2vw solid #fc9627;
        .orangeBlock {
          display: block;
        }
        a {
          color: #0f71a6;
        }
      }
    }
  }
}
</style>
